import { lazy, Suspense, useState, useEffect, FC, MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFragment, useRelayEnvironment, graphql } from 'react-relay';
import { isMasqueradingAsBuyer, isDirectlyLoggedInAsUser } from 'dibs-cookie-jar';
import { localStorage } from 'dibs-browser-storage';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import Dropdown, { alignConstants, sizeConstants } from '../Dropdown/Dropdown';
import DropdownBody from '../Dropdown/DropdownBody';
import authModalLoader from '../../utils/AuthModalLoader';
import AccountNavTrigger from './AccountNavTrigger';
import BuyerAccountNavContent from './AccountNavContent';
import { Link } from 'dibs-elements/exports/Link';

import { ACTION_REGISTER, ACTION_LOGIN } from './accountConstants';

import styles from './AccountNav-style.scss';
import { actionCreators } from '../../actions';

import DropdownLoadingContent from '../Dropdown/DropdownLoadingContent';

import { AccountNav_user$key } from './__generated__/AccountNav_user.graphql';
import { AccountNav_viewer$key } from './__generated__/AccountNav_viewer.graphql';

const TradeAccountNavContentLazy = lazy(
    () =>
        import(
            /* webpackChunkName: "tradeAccountNavContent" */
            './TradeAccountNavContent'
        )
);

type ReduxStoreType = {
    header: {
        isVipCuratedFolderTooltipVisible: boolean;
    };
};

type AccountNavProps = {
    hasUser: boolean;
    hasUserId: boolean;
    isClient: boolean;
    user: AccountNav_user$key;
    hasTradeUserId: boolean;
    viewer: AccountNav_viewer$key;
};

const AccountNav: FC<AccountNavProps> = ({
    hasUser,
    hasUserId,
    isClient,
    user: userRef,
    hasTradeUserId,
    viewer: viewerRef,
}) => {
    const [isMasquerading, setIsMasquerading] = useState(false);
    const [isDirectLogin, setIsDirectLogin] = useState(false);
    const relayEnvironment = useRelayEnvironment();
    const user = useFragment(
        graphql`
            fragment AccountNav_user on User {
                ...AccountNavContent_user @defer
                ...AccountNavTrigger_user @defer
                ...TradeAccountNavContent_user @include(if: $hasTradeUserId) @defer
                isVerifiedTrade
            }
        `,
        userRef
    );
    const viewer = useFragment(
        graphql`
            fragment AccountNav_viewer on Viewer {
                ...AccountNavContent_viewer
                ...TradeAccountNavContent_viewer
            }
        `,
        viewerRef
    );
    useEffect(() => {
        setIsMasquerading(isMasqueradingAsBuyer(document.cookie));
        setIsDirectLogin(isDirectlyLoggedInAsUser(document.cookie));
    }, []);

    const isVipCuratedFolderTooltipVisible = useSelector<ReduxStoreType, boolean>(
        ({ header }) => header.isVipCuratedFolderTooltipVisible
    );
    const dispatch = useDispatch();

    const handleClick: MouseEventHandler<HTMLButtonElement> = async e => {
        e.preventDefault();
        const action = e.currentTarget.dataset.action;
        try {
            const { authError } = await authModalLoader.show({
                relayEnvironment,
                email: localStorage.getItem('userEmail'),
                action,
                ga: {
                    label: `${action} link - global header`,
                    value: !!window.scrollY,
                },
            });
            if (!authError) {
                dispatch(actionCreators.setUpdateUserState(true));
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Auth Modal could not be loaded\n', error);
        }
    };

    if (!isClient || (hasUserId && !hasUser)) {
        return null;
    }

    const isVerifiedTrade = user?.isVerifiedTrade;

    return (
        <div data-tn="account-nav" className={styles.wrapper}>
            {hasUser ? (
                <>
                    <Dropdown
                        align={alignConstants.ALIGN_CENTER}
                        size={sizeConstants.SIZE_SMALL}
                        className={classnames({
                            [styles.noHover]: isVipCuratedFolderTooltipVisible,
                        })}
                        bodyClassName={styles.dropdownBodyWrapper}
                        dropdownClassName={styles.dropdown}
                    >
                        <Suspense fallback={null}>
                            <AccountNavTrigger
                                isMasquerading={isMasquerading}
                                isDirectLogin={isDirectLogin}
                                user={user}
                            />
                        </Suspense>
                        <DropdownBody>
                            {isVerifiedTrade && hasTradeUserId ? (
                                <Suspense fallback={<DropdownLoadingContent />}>
                                    <TradeAccountNavContentLazy user={user} viewer={viewer} />
                                </Suspense>
                            ) : (
                                <Suspense fallback={<DropdownLoadingContent />}>
                                    <BuyerAccountNavContent user={user} viewer={viewer} />
                                </Suspense>
                            )}
                        </DropdownBody>
                    </Dropdown>
                </>
            ) : (
                <div className={styles.authButtons}>
                    <Link
                        className={styles.authButton}
                        dataTn="user-menu-nav-item-link-log-in"
                        dataAction={ACTION_LOGIN}
                        onClick={handleClick}
                    >
                        <FormattedMessage
                            id="dbl.Header.AccountNav.log_in"
                            defaultMessage={'Log In'}
                        />
                    </Link>
                    <Link
                        className={styles.authButton}
                        dataTn="user-menu-nav-item-link-sign-up"
                        dataAction={ACTION_REGISTER}
                        onClick={handleClick}
                    >
                        <FormattedMessage
                            id="dbl.Header.AccountNav.sign_up"
                            defaultMessage={'Sign Up'}
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

export default AccountNav;
